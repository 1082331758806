import $ from 'jquery';

/**
 * GETパラメータ展開
 */
const purseParams = (string) => {
  // 変数用意
  const params = {};
  // 文字列展開
  string.split('&').forEach((param) => {
    // プロパティ展開
    const prop = param.split('=');
    // 挿入
    params[prop[0]] = prop[1];
  });
  // 返却
  return params;
};

(() => {
  // ヘッダーのセレクター
  const SELECTOR_HEAD = '.l-header';
  // スクロールスピード
  const SCROLL_SPEED = 500;

  window.addEventListener('load', () => {
    // ヘッダーオブジェクト
    const object = document.querySelector(SELECTOR_HEAD);
    // オフセット量
    const offset = object ? object.offsetHeight : 0;
    // パラメータの展開
    const params = purseParams(window.location.search.replace('?', ''));
    // scroll値がない場合は終了
    if (!params.scroll) return false;
    // ポジションを算出
    const position = $(`#${params.scroll}`).offset().top - offset;
    // スクロール実行
    $('html, body').animate({ scrollTop: position }, SCROLL_SPEED, 'swing');
    return false;
  });
})();
