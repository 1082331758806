// import modules
import React from 'react';

// import css
import stylePc from './style.sass';
import styleSp from './style.sp.sass';

// イベント
const EVENT_NAME = 'GATEWAY';

/**
 *  Courses Class
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default class Table extends React.Component {
  /**
   *  コンストラクタ
   *  @version 2017/01/05
   *  @author ryo-as
   */
  constructor(props) {
    super(props);
    // initialize state.
    this.state = { open: false };
  }
  /**
   *  初期描画直後に一度だけ実行
   *  @version 2017/01/05
   *  @author ryo-as
   */
  componentWillMount() {
    // add listener.
    this.emitter = emitter.on(EVENT_NAME, () => {
      this.setState({ open: !this.state.open });
    });
  }

  /**
   *  アンマウント直前に実行
   *  @version 2017/01/05
   *  @author ryo-as
   */
  componentWillUnmount() {
    // remove listener.
    this.emitter = null;
  }

  /**
   *  閉じる
   *  @version 2017/01/05
   *  @author ryo-as
   */
  close() {
    this.setState({ open: !this.state.open });
  }

  /**
   *  親要素のクリックイベントを引き継がない
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  _stopPropagation(event) {
    event.stopPropagation();
  }

  /**
   *  表示処理
   *  @version 2017/01/05
   *  @author ryo-as
   */
  render() {
    // 非表示
    if (!this.state.open) return null;
    const { device } = this.props;
    const style = device === 'pc' ? stylePc : styleSp;

    // 表示
    return (
      <div className='c-modal'>
        <div className='c-modal__overlay' onClick={::this.close}>
          <div className={`${style.modal} c-modal__wrap`} onClick={this._stopPropagation}>
            <div className={style.title}>どちらでログインしますか？</div>
            <div className={style.wrap}>
              <div className={style.target}>
                <p>保護者の方はこちら</p>
                <a
                  className={`${style.button} c-btnMain-standard c-btnSize-48 c-btn-radius c-btnSize-50`}
                  href='/managers/sign_in'
                  target='_blank'
                  onClick={::this.close}
                >
                  登録内容を確認する
                </a>
              </div>
              <div className={style.target}>
                <p>受講生はこちら</p>
                <a
                  className={`${style.button} c-btnMain-primaryB c-btnSize-48 c-btn-radius c-btnSize-50`}
                  href='/students/sign_in'
                  target='_blank'
                  onClick={::this.close}
                >
                  レッスンをはじめる
                </a>
              </div>
            </div>
            <div onClick={::this.close} className='c-modal__close-icon'>
              ×
            </div>
          </div>
        </div>
      </div>
    );
  }
}
